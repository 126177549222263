var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{staticClass:"w100p",attrs:{"flat":""}}),_c('div',{staticClass:"d-sm-flex mb-3 text-Heading6 Medium d-none"},[_vm._v(" "+_vm._s(_vm.$store.getters['app/getRouteTitle'])+" ")]),_c('FilterBox',{attrs:{"open":_vm.isFilterOpen,"loading":_vm.isLoading,"options":_vm.filterOptions},on:{"update:open":function($event){_vm.isFilterOpen=$event},"search":_vm.fetchData},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_c('v-card',{attrs:{"outlined":"","color":_vm.$vuetify.breakpoint.smAndDown ? 'transparent' : null}},[_c('v-card-text',{staticClass:"d-flex align-center pb-0 pb-sm-4"},[_c('v-spacer'),_c('RefreshBtn',{attrs:{"fetchData":_vm.fetchData,"loading":_vm.isLoading}}),_c('SizeBox',{attrs:{"width":"14"}})],1),_c('DataTable',{attrs:{"fields":_vm.headers,"list":_vm.result.list,"total":_vm.result.total,"loading":_vm.isLoading,"labels":_vm.labels,"replace":[
        'actions',
        'to_unfreeze_at|formatTime',
        'unfroze_at',
        'created_at|formatTime',
        'unfrozen_note'
      ]},on:{"get-list":_vm.fetchData},scopedSlots:_vm._u([{key:"item.actions",fn:function({ item }){return [_c('div',{staticClass:"flex-center"},[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"color":"Secondary100","rounded":"","small":_vm.$vuetify.breakpoint.smAndDown}},on),[_vm._v(" 操作 "),_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}}],null,true)},[_c('v-list',{attrs:{"rounded":""}},_vm._l((item.actions),function(key,i){return _c('v-list-item',{key:`key:${item.id} ${i}`,attrs:{"disabled":key.grant ? !_vm.$getGrantValue(key.grant) : false},on:{"click":function($event){key.action && _vm.handleAction((vm) => key.action(vm, item))}}},[(!!key.icon)?_c('v-icon',{staticClass:"mr-1",attrs:{"size":"18"}},[_vm._v(" "+_vm._s(key.icon)+" ")]):_vm._e(),_vm._v(" "+_vm._s(key.label)+" ")],1)}),1)],1)],1)]}},{key:"item.unfroze_at",fn:function({ item }){return [(item.unfroze_at)?_c('div',[_vm._v(" "+_vm._s(_vm._f("formatTime")(item.unfroze_at))+" ")]):_c('div',{staticClass:"error--text"},[_vm._v("未解凍")])]}},{key:"item.unfrozen_note",fn:function({ item }){return [_c('div',{staticClass:"note-wrap"},[_vm._v(_vm._s(item.unfrozen_note))])]}}])})],1),_c('UnfreezeForm',{attrs:{"value":_vm.showingForm === 'Unfreeze',"passData":_vm.passData},on:{"close":function($event){_vm.showingForm = null},"save":_vm.fetchData}}),_c('EditNoteForm',{attrs:{"value":_vm.showingForm === 'EditNote',"passData":_vm.passData},on:{"close":function($event){_vm.showingForm = null},"save":_vm.fetchData}}),_c('FunctionFab',{attrs:{"no-filter":!_vm.filterOptions.length,"no-info":!Object.entries(_vm.result.header_info).length},on:{"info":function($event){_vm.isInfoOpen = true},"refresh":function($event){return _vm.fetchData()},"open-filter":function($event){_vm.isFilterOpen = true}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }