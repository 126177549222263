<template>
  <v-dialog
    :value="value"
    scrollable
    persistent
    :overlay="false"
    max-width="800"
    transition="slide-y-transition"
  >
    <v-card min-height="500">
      <v-card-title class="pos-r">
        解凍
        <v-spacer></v-spacer>
        <v-btn
          rounded
          color="Secondary100"
          fab
          x-small
          class="b-rad-12"
          @click="close"
        >
          <v-icon>mdi-window-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <SizeBox height="48" />

        <v-form ref="form">
          <v-textarea
            label="解凍備註"
            v-model="form.unfrozen_note"
            :rules="[$rules.required]"
            rounded
            outlined
            auto-grow
          ></v-textarea>

          <v-text-field
            rounded
            label="谷歌驗證碼"
            v-model="form.otp_code"
            :rules="[$rules.required]"
            outlined
          ></v-text-field>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn
          rounded
          color="Secondary100"
          depressed
          large
          :disabled="loading"
          @click="close"
        >
          取消
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          rounded
          color="primary"
          dark
          depressed
          large
          :loading="loading"
          @click="submit"
        >
          解凍
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { unfreezeFrozenOrder } from '@/api/capitalLogs';

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    passData: {
      type: Object,
      default: () => ({
        /**
         * mode: String, // new, edit
         */
      })
    }
  },

  data() {
    return {
      loading: false,
      form: {
        id: '',
        unfrozen_note: '',
        otp_code: ''
      }
    };
  },

  watch: {
    value(v) {
      v ? this.init() : this.reset();
    }
  },

  methods: {
    init() {
      this.form.id = this.passData.id;
    },
    reset() {
      this.form = {
        id: '',
        unfrozen_note: '',
        otp_code: ''
      };
    },
    close() {
      this.$emit('close');
    },
    async submit() {
      if (!this.$refs.form.validate()) return;
      this.loading = true;

      const { error } = await unfreezeFrozenOrder(this.form);

      if (!error) {
        this.$toast('解凍成功');
        this.$emit('save');
        this.close();
      } else {
        this.$toast.error('解凍失敗');
        error.detail &&
          error.detail.message &&
          this.$toast.error(error.detail.message);
      }

      this.loading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
//
</style>