<template>
  <!-- eslint-disable  -->
  <div>
    <v-card flat class="w100p">
      <!--  -->
    </v-card>
    <div class="d-sm-flex mb-3 text-Heading6 Medium d-none">
      {{ $store.getters['app/getRouteTitle'] }}
    </div>
    <FilterBox
      :open.sync="isFilterOpen"
      v-model="filter"
      :loading="isLoading"
      :options="filterOptions"
      @search="fetchData"
    />
    <v-card
      outlined
      :color="$vuetify.breakpoint.smAndDown ? 'transparent' : null"
    >
      <v-card-text class="d-flex align-center pb-0 pb-sm-4">
        <v-spacer></v-spacer>
        <RefreshBtn :fetchData="fetchData" :loading="isLoading" />

        <SizeBox width="14" />
      </v-card-text>
      <DataTable
        :fields="headers"
        :list="result.list"
        :total="result.total"
        :loading="isLoading"
        @get-list="fetchData"
        :labels="labels"
        :replace="[
          'actions',
          'to_unfreeze_at|formatTime',
          'unfroze_at',
          'created_at|formatTime',
          'unfrozen_note'
        ]"
      >
        <template #item.actions="{ item }">
          <div class="flex-center">
            <v-menu>
              <template #activator="{ on }">
                <v-btn
                  v-on="on"
                  color="Secondary100"
                  rounded
                  :small="$vuetify.breakpoint.smAndDown"
                >
                  操作
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list rounded>
                <v-list-item
                  v-for="(key, i) in item.actions"
                  :key="`key:${item.id} ${i}`"
                  @click="
                    key.action && handleAction((vm) => key.action(vm, item))
                  "
                  :disabled="key.grant ? !$getGrantValue(key.grant) : false"
                >
                  <v-icon v-if="!!key.icon" size="18" class="mr-1">
                    {{ key.icon }}
                  </v-icon>
                  {{ key.label }}
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>
        <template #item.unfroze_at="{ item }">
          <div v-if="item.unfroze_at">
            {{ item.unfroze_at | formatTime }}
          </div>
          <div v-else class="error--text">未解凍</div>
        </template>
        <template #item.unfrozen_note="{ item }">
          <div class="note-wrap">{{ item.unfrozen_note }}</div>
        </template>
      </DataTable>
    </v-card>

    <UnfreezeForm
      :value="showingForm === 'Unfreeze'"
      @close="showingForm = null"
      @save="fetchData"
      :passData="passData"
    />
    <EditNoteForm
      :value="showingForm === 'EditNote'"
      @close="showingForm = null"
      @save="fetchData"
      :passData="passData"
    />
    <FunctionFab
      @info="isInfoOpen = true"
      @refresh="fetchData()"
      @open-filter="isFilterOpen = true"
      :no-filter="!filterOptions.length"
      :no-info="!Object.entries(result.header_info).length"
    />
  </div>
</template>

<script>
import { getFrozenOrders } from '@/api/capitalLogs';

import UnfreezeForm from '@/components/funding/frozen/UnfreezeForm.vue';
import EditNoteForm from '@/components/funding/frozen/EditNoteForm.vue';

const actions = [
  {
    label: '解凍',
    icon: 'mdi-snowflake',
    grant: 'frozen_log_list.unfreeze',
    action: (vm, v) => {
      vm.openForm('Unfreeze', v);
    }
  },
  {
    label: '編輯備註',
    icon: 'mdi-pencil-outline',
    grant: 'frozen_log_list.edit_freeze_note',
    action: (vm, v) => {
      vm.openForm('EditNote', v);
    }
  }
];

export default {
  components: { UnfreezeForm, EditNoteForm },
  data() {
    return {
      ...this.$clone(this.$commonSchema),

      headers: [
        { text: '操作', value: 'actions', align: 'center' },
        {
          text: '凍結狀態',
          value: 'status',
          align: 'center',
          width: '80px'
        },
        {
          text: '凍結單號',
          value: 'order_no',
          align: 'center',
          width: '220px'
        },
        {
          text: '商戶編號',
          value: 'merchant.serial',
          align: 'center',
          width: '90px'
        },
        {
          text: '凍結金額',
          value: 'amt',
          align: 'center',
          width: '100px'
        },
        {
          text: '凍結備註',
          value: 'frozen_note',
          align: 'center',
          width: '100px'
        },
        {
          text: '建立時間',
          value: 'created_at',
          align: 'center',
          width: '120px'
        },
        {
          text: '計畫解凍時間',
          value: 'to_unfreeze_at',
          align: 'center',
          width: '120px'
        },
        {
          text: '實際解凍時間',
          value: 'unfroze_at',
          align: 'center',
          width: '120px'
        },
        {
          text: '解凍備註',
          value: 'unfrozen_note',
          align: 'center',
          width: '120px'
        }
      ],
      labels: {
        status: [
          {
            text: '凍結中',
            value: 'frozen',
            color: 'Error300'
          },
          {
            text: '已解凍',
            value: 'unfrozen',
            color: 'Success200'
          }
        ]
      },
      filterOptions: [
        {
          label: '建立時間',
          type: 'timeSet',
          name: 'created_at',
          range: true,
          withTime: true
        },
        {
          label: '商戶 ID',
          type: 'text',
          name: 'query.merchant_serial'
        },
        {
          label: '狀態',
          type: 'select',
          items: [
            { text: '凍結中', value: 'frozen' },
            { text: '已解凍', value: 'unfrozen' }
          ],
          name: 'status'
        }
      ]
    };
  },

  async created() {
    this.fetchData();
  },

  methods: {
    async fetchData(condition = this.condition) {
      this.isLoading = true;
      this.condition = condition;
      const History = await getFrozenOrders({
        ...this.filter,
        ...condition
      });
      if (!History.error) {
        this.result.total = History.total;
        this.result.page = History.page;
        this.$set(
          this.result,
          'list',
          History.items.map((item) => ({
            ...item,
            actions
          }))
        );
      }

      this.isLoading = false;
    },
    openForm(name, data = {}, mode = '') {
      this.showingForm = name;

      this.$set(this, 'passData', {
        mode,
        ...data
      });
    },
    handleAction(fn) {
      fn(this);
    }
  }
};
</script>

<style lang="scss" scoped>
//
</style>
